import { assignBucketToCompany } from "../../store/actions/commonAction";

export default {
  references: "/references",
  referenceSearch: "/references-search",
  scopers: "/scopers",
  fileMatricsTemplate: "/file-metrics/template",
  cookieStoreTemplate: "/cookie-store/template",
  fileMatrics: "/file-metrics",
  cookieStore: "/cookie-store",
  tpPipeline: "/tp-status",
  tpPipelineNew: "/pipeline-records",
  companyUpdates: "/company-updates",
  companyUpdateType: "/company-updates/types",
  favorite: "/company-favorite",
  emailHistory: "/email-history",
  tPOwner: "/Tp-owner",
  fileUploads: "/file-uploads",
  documentTypes: "/document-types",
  uploadFile: "/file-uploads",
  documentMeta: "/file-uploads/metadata",
  spyfuMetrics: "/spyfu-metrics",
  spyfuFilter: "/spyfu-filter",
  linkedinMetrics: "/linkedin-metrics",
  linkedinFilter: "/linkedin-filter",
  alexaMetrics: "/alexa-metrics",
  alexaFilter: "/alexa-filter",
  projects: "/projects",
  templates: "/templates",
  notifications: "/notification-template",
  notificationTemplate: "/notification/template",
  companySearch: "/company-search",
  companyAlert: "/alert-company",
  companyPage: "/company-page",
  alert: "/alert-company",
  alertCompany: "/alert",
  downStreamFilter: "/downstream-filter",
  combinedFilter: "/combined-downstream",
  cities: "/linkedin/cities",
  states: "/linkedin/states",
  meetingStat:"/meeting-stat",
  tpStatus:"/tp-status-history",
  pipelineTpStatus:"/pipeline-tp-status",
  pipelineTpFilterOPS:"/pipeline-operation",
  sources: "/source-list",
  companyProject:"/company-project",
  addTpOwner:"/add-tp-owner",
  addTpStatus:"/assign-tp-status",
  emailChart: "/conversation-contribution",
  emailDetails: "/conversation-insight",
  draftList: "/unpublished/file-metrics",
  draftDetails:"/draft/file-metrics",
  universeInsights:"/universe-insight",
  management : "/management",
  pipelineManagement:"/pipeline-management",
  pipelineViewAll:"/universe-pipeline",
  employeePopulation: "/employee-population-details",
  affinityList: "/all-lists",
  addCompanyList: "/add-company-to-list",
  getFunction: '/conversations/meta-data',
  getReferenceHeader: '/conversations/header-block',
  getIncompltete: '/conversations/incomplete',
  conversationSingleReference:"/conversations",
  allreferences: "/conversations/all",
  getCompaniesWorkboard:"/company-search",
  getWorkboardCategories:"/company-search",
  getWorkboardPeople:"/scopers",
  updatePerson: "/people-metadata-update",
  createWorkbook: "/create-workbook",
  myWorkboards: "/workboards/all",
  addPersonToRef: "/add-person-to-workboard",
  getDiscoveryCompanies: "/discovery",
  getDiscoveryFiltersData: "/discovery/filters-data",
  getInvestorReminders: "/scoper-reminders",
  setInvestorRemindersStatus: '/set-scoper-reminder-status',
  setLLMFilter:'/discovery/llm-filter',
  discoveryWatch: '/discovery/watch',
  tpStatusOptions:"/tp-status-options",
  pipelineTpOwner:"/pipeline-tp-owner",
  allListsAndBuckets: "/all-lists-and-buckets",
  companyLists: '/company-lists',
  companyNoCachedData: '/get-company-no-cached-data',
  companycachedData: '/get-company-cached-data',
  findfounderData: '/find-founder-data',
  advertisingStats: '/get-company-advertising-stats',
  companyCompetitors: '/get-company-competitors',
  addAdvertisingStats: '/add-company-advertising-stats',
  addCompanyCompetitors: '/add-company-competitors',
  findCompanyFounders: '/find-company-founders',
  assignBucketToCompany: '/assign-bucket-to-company',
  addNewList: '/add-list',
  quipsData: '/quips',
  knowledgeSource: '/knowledge-source',
  mentionsData: '/mentions',
  getLunchesData: '/ctolunches'
};

