import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { Accordion } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { initializeLogout, getNoteTemplate, setSubActionItemSubmissions } from '../../store/actions';
import { axiosManagement } from "../../config/appConfig/axiosInstance";
import { LoadingBlock } from "../../commonComponents/LoadingAnimation/LoaderComponents";
import { ReactComponent as SidebarSvg } from "../../assets/images/icon_svg/sidebar.svg";
import { ReactComponent as DashboardSvg } from "../../assets/images/icon_svg/dashboard.svg";
import { ReactComponent as SearchBarSvg } from '../../assets/images/icon_svg/searchbar.svg';
import { ReactComponent as DiscoverySvg } from '../../assets/images/icon_svg/discovery.svg';
import { ReactComponent as ConversationsSvg } from '../../assets/images/icon_svg/conversation.svg';
import { ReactComponent as ActionSvg } from '../../assets/images/icon_svg/action.svg';
import { ReactComponent as WorkboardSvg } from '../../assets/images/icon_svg/workboard.svg';
import { ReactComponent as LogoutSvg } from '../../assets/images/icon_svg/logout.svg';
import { ReactComponent as ArrowUpSvg } from "../../assets/images/icon_svg/arrows-up.svg";
import { ReactComponent as ArrowDownSvg } from "../../assets/images/icon_svg/arrows-down.svg";
import { ReactComponent as InvestorReminderSvg } from "../../assets/images/icon_svg/investor_reminder.svg";
import { ReactComponent as WeeklyMetricsSVG } from "../../assets/images/icon_svg/weekly_metrics.svg";
import "./sideMenu.scss";

const navItems = [
    { name: 'Discovery', path: 'discovery' },
    { name: 'Conversations', path: 'conversations' },
    { name: 'Associate Weekly Metrics', path: 'submissions' },
    { name: 'Investor Reminders', path: 'investorReminders' },
];

const SideMenu = ({ isSidebarOpen, toggleSidebar }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [templateLoading, setTemplateLoading] = useState(false);
    const [isSectionCollapsed, setIsSectionCollapsed] = useState(false);
    const [actionsAccordionExpanded, setActionsAccordionExpanded] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const [actions, setActions] = useState("");

    const [noteTemplate] = useSelector(({ commonReducer }) => [commonReducer?.noteTemplate])
    const hasKeys = Object?.keys(noteTemplate).length;

    useEffect(() => {
        setTemplateLoading(true)
        !Object?.keys(noteTemplate)?.length && dispatch(getNoteTemplate(axiosManagement)).then(res => setTemplateLoading(false))
    }, [])

    useEffect(() => {
   
        if (localStorage.getItem("externalRoutes")) {
            let redirect = JSON.parse(localStorage.getItem("externalRoutes"))
            let url = redirect?.pathname + redirect?.search
            setActiveTab(`${redirect?.pathname}`);
            navigate(`${url}`);
            localStorage.removeItem("externalRoutes")
            return
        }

        if (location?.pathname.replace(/\//g, '') != "submissions") {
            setActiveTab(location?.pathname.replace(/\//g, ''))
            localStorage.removeItem("currentTemplete")
            setActionsAccordionExpanded(false);
            setActions("");

        } else if (location?.pathname.replace(/\//g, '') === "submissions") {
            const selectedActionTemplate = localStorage.getItem("currentTemplete");
            let templateObj = selectedActionTemplate ?  JSON.parse(selectedActionTemplate) : null

            if(templateObj && templateObj?.name === "Associate Weekly Metrics") {
                setActions("");
                setActiveTab("Associate Weekly Metrics");
                if (noteTemplate && Object?.keys(noteTemplate).length) {
                    dispatch(setSubActionItemSubmissions(templateObj))
                }
            } else {
                setActions("Actions");
                setActionsAccordionExpanded(true);
                setActiveTab(templateObj?.name)
                if (noteTemplate && Object?.keys(noteTemplate).length) {
                    dispatch(setSubActionItemSubmissions(templateObj))
                }
            }
        }

        if (location?.pathname.replace(/\//g, '') === 'workboard') {
            if (localStorage.getItem("workboard")) {
                let selectedWorkboard = localStorage.getItem("workboard")
                setActiveTab(selectedWorkboard)
            } else {
                setActiveTab('myWorkboards')
            }
        }
        else if (location?.pathname.startsWith('/workboard/')) {
            setActiveTab('myWorkboards');
        }
    }, [location.pathname, noteTemplate]);

    const toggleSidebarAndActions = () => {
        // Toggle the sidebar only when it is collapsed for Actions Tab
        if (!isSidebarOpen && location.pathname === '/submissions') {
            setActionsAccordionExpanded(true);
        } else {
            setActionsAccordionExpanded(false);
        }
        // Toggle the sidebar
        toggleSidebar();
    };

    function handleWorkboardSubItem() {
        setActiveTab('myWorkboards');
        navigate('/workboard')
        setActions("");
        localStorage.removeItem("currentTemplete")
        localStorage.setItem("workboard", "myWorkboards")
        amplitude.logEvent(`Workbaord Clicked`);
    }

    const handleRoute = (item) => {

        if(item.name === "Associate Weekly Metrics") {
            setActions("")
            setActiveTab("Associate Weekly Metrics")
            if (noteTemplate && Object.keys(noteTemplate).length) {
                dispatch(setSubActionItemSubmissions({ name: "Associate Weekly Metrics", url: noteTemplate?.[`Knowledge Management`]?.[`Associate Weekly Metrics`] }))
                
            }
            localStorage.setItem("currentTemplete", JSON.stringify({ name: "Associate Weekly Metrics", url: noteTemplate?.[`Knowledge Management`]?.[`Associate Weekly Metrics`]}))
        }
        navigate(`/${item?.path}`);
        localStorage.removeItem("workboard");
        amplitude.logEvent(`${item.name} Clicked`);
    };


    function handleActionSubItem(obj, item) {

        dispatch(setSubActionItemSubmissions(obj))
        setActiveTab(item)
        navigate('/submissions');
        localStorage.setItem("currentTemplete", JSON.stringify({ ...obj }))
        localStorage.removeItem("workboard")
        amplitude.logEvent(`${item} Clicked`);
    }

    const handleActionsButtonClick = (e) => {
        e.stopPropagation();
        if (!isSidebarOpen) {
            toggleSidebar();
        }
        setIsSectionCollapsed(true);
        setActionsAccordionExpanded(true);

        if (noteTemplate && Object?.keys(noteTemplate).length) {
            setActions("Actions");
            setActiveTab("Board Meeting Notes")
            dispatch(setSubActionItemSubmissions({ name: "Board Meeting Notes", url: noteTemplate?.[`Knowledge Management`]?.[`Board Meeting Notes`] }))
            localStorage.setItem("currentTemplete", JSON.stringify({ name: "Board Meeting Notes", url: noteTemplate?.[`Knowledge Management`]?.[`Board Meeting Notes`]}))
            localStorage.removeItem("workboard");
        }
        navigate('/submissions')
    };

    const handleAccordion = (ev) => {
        ev?.stopPropagation();
        setActionsAccordionExpanded(!actionsAccordionExpanded);
        setIsSectionCollapsed(!isSectionCollapsed) 
    }

    const handleLogout = () => {
        navigate("/workboard", { replace: true });
        dispatch(initializeLogout())
        amplitude.logEvent(`User Logout`);
    }

    return (
        <div id='sidemenu-container'>
            <button className="toggle-button" onClick={toggleSidebarAndActions}>
                <SidebarSvg />
            </button>
            <div className={`panel flex-shrink-0 bg-white ${isSidebarOpen ? '' : 'collapsed'}`}>
                <div className={`sidebar-tab`} onClick={e => handleWorkboardSubItem()}>
                    {isSidebarOpen ? (
                        <div className="icon-container-wrapper">
                            <div className={`icon-container ${activeTab === 'myWorkboards' ? 'active-icon' : ''}`}>
                                <WorkboardSvg className={`workboard-icon icon ${activeTab === 'myWorkboards' ? 'active-icon' : ''}`} />
                                <span className={`component-name ${activeTab === 'myWorkboards' ? 'active-component-name' : ''}`}>
                                    Workboards
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className={`tab-container ${activeTab === 'myWorkboards' ? 'active-tab' : ''}`}>
                            <WorkboardSvg className={`workboard-icon icon ${activeTab === 'myWorkboards' ? 'active-icon' : ''}`} />
                        </div>
                    )}
                </div>
                <div>
                    {navItems.map((navItem, index) => {
                        const isActive = activeTab === navItem.path || (activeTab === 'Associate Weekly Metrics' && navItem.path === 'submissions');
                        return (
                            <div
                                className='sidebar-tab'
                                onClick={() => handleRoute(navItem)}
                                key={index}
                            >
                                {isSidebarOpen ? (
                                    <>
                                        <div className="icon-container-wrapper">
                                            <div className={`icon-container ${isActive ? 'active-icon' : ''}`}>
                                                {navItem.name === 'Associate Weekly Metrics' && <WeeklyMetricsSVG className={`metrics-icon icon ${isActive ? 'active-icon' : ''}`} />}
                                                {navItem.name === 'Discovery' && <DiscoverySvg className={`icon ${isActive ? 'active-icon' : ''}`} />}
                                                {navItem.name === 'Conversations' && <ConversationsSvg className={`icon ${isActive ? 'active-icon' : ''}`} />}
                                                {navItem.name === 'Investor Reminders' && <InvestorReminderSvg className={`investor-icon icon ${isActive ? 'active-icon' : ''}`} />}

                                                <span className={`component-name ${isActive ? 'active-component-name' : ''}`}>
                                                    {navItem.name}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={`tab-container ${isActive ? 'active-tab' : ''}`}>
                                            {navItem.name === 'Discovery' && (
                                                <div>
                                                    <DiscoverySvg className={`icon ${isActive ? 'active-icon' : ''}`} />
                                                </div>
                                            )}
                                            {navItem.name === 'Conversations' && (
                                                <div>
                                                    <ConversationsSvg className={`icon ${isActive ? 'active-icon' : ''}`} />
                                                </div>
                                            )}
                                             {navItem.name === 'Associate Weekly Metrics' && (
                                                <div>
                                                    <WeeklyMetricsSVG className={`metrics-icon icon ${isActive ? 'active-icon' : ''}`} />
                                                </div>
                                            )}
                                            {navItem.name === 'Investor Reminders' && (
                                                <div>
                                                    <InvestorReminderSvg className={`investor-icon  icon ${isActive ? 'active-icon' : ''}`} />
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="accordion-tab d-flex flex-column flex-grow-1">
                    <Accordion className='template-actions' activeKey={actionsAccordionExpanded ? '0' : null}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header onClick={handleAccordion}>
                                {isSidebarOpen ? (
                                    <div className="actions-container">
                                        <ActionSvg className={`actions-icon ${actions === 'Actions' ? 'active' : ''}`} />
                                        <span className={`component-name ${actions === 'Actions' ? 'active-component-name' : ''}`}>
                                            Actions
                                        </span>
                                        <span
                                            className='triangle'
                                            role='button'
                                            aria-label='triangle'
                                        >
                                            {actionsAccordionExpanded ? <ArrowUpSvg /> : <ArrowDownSvg />}
                                        </span>
                                    </div>
                                ) : (
                                    <div className={`tab-container ${actions === 'Actions' ? 'active-tab' : ''}`} onClick={(e) => handleActionsButtonClick(e)}>
                                        <ActionSvg className={`icon ${actions === 'Actions' ? 'active-icon' : ''}`} />
                                    </div>
                                )}
                            </Accordion.Header>
                            <Accordion.Body className={isSidebarOpen ? 'expanded' : 'collapsed'}>
                                {isSidebarOpen && (
                                    <>
                                        {templateLoading ? (
                                            <div style={{ paddingLeft: "-4px" }}>
                                                <LoadingBlock height="30px" width="auto" className="mb-2 " />
                                                <LoadingBlock height="30px" width="auto" className="mb-2 " />
                                                <LoadingBlock height="30px" width="auto" className="mb-2 " />
                                                <LoadingBlock height="30px" width="auto" className="mb-2 " />
                                            </div>
                                        ) : (
                                            Object?.keys(noteTemplate)?.length ? (
                                                hasKeys &&
                                                Object?.keys(noteTemplate)?.map((item, index) => {
                                                    let children = noteTemplate[item];
                                                    return (
                                                        <div className="" key={`template-${index}`}>
                                                            <div className="action-item">{item}</div>
                                                            {Object.keys(children)?.filter(filtered => filtered !== "Associate Weekly Metrics").map((item2, index2) => {
                                                                return (
                                                                    <div
                                                                    key={`template-${index2}`}
                                                                        className={
                                                                            activeTab === item2
                                                                                ? 'sidebar-tab action-sub-item action-sub-tab-active mb-1 '
                                                                                : 'action-sub-item mb-1'
                                                                        }
                                                                        onClick={(e) =>
                                                                            handleActionSubItem({ name: item2, url: children[item2] }, item2)
                                                                        }
                                                                    >
                                                                        {item2}
                                                                    </div>
                                                                );
                                                            })}
                                                            <div className="hr"></div>
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="no-template action-sub-item mb-1">
                                                    No template available
                                                </div>
                                            )
                                        )}
                                    </>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                
                <div className='logout-sidemenu' onClick={handleLogout}>
                    {isSidebarOpen ? (
                        <span className='logout-text'>Logout</span>
                    ) : (
                        <LogoutSvg className="logout-icon" />
                    )}
                </div>
            </div>
        </div>
    );
}

export default SideMenu;    