import React, { useState, useEffect } from 'react'
// import CreateWorkboard from './CreateWorkboard/CreateWorkboard';
import MyWorkboards from './MyWorkboards/MyWorkboards';
// import {UpgradedWorkboard} from "./UpgradedWorkboard/UpgradedWorkboard"

// import { useSelector, useDispatch } from 'react-redux'

function Workboard() {
    // const dispatch = useDispatch()
    // let [activePage, setActivePage] = useState('createWorkboard')

    // const { activeWorkboard } = useSelector((state => state.commonReducer))

    // useEffect(()=>{
    //     if (!activeWorkboard) {
    //         setActivePage('createWorkboard')
    //     }
    // }, [])

    // useEffect(()=>{
    //     if(activeWorkboard) {
    //         setActivePage(activeWorkboard)
    //     }
    // }, [activeWorkboard])

    return <>
        <MyWorkboards />
        {/* {activePage === 'createWorkboard' && <CreateWorkboard />} */}
        {/* {activePage === 'myWorkboards' && <MyWorkboards />} */}
        {/* {activePage === 'upgradedWorkboards' && <UpgradedWorkboard />} */}
    </>
}

export default Workboard;